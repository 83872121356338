<template>
  <b-dropdown
    :class="{ 'teacher-course-content__dropdown--mb-40': contentLevel !== 0 }"
    size="lg"
    variant="link"
    toggle-class="text-decoration-none"
    no-caret
    id="dropdown-1"
  >
    <template #button-content>
      <AddButtonWithIcon :title="$t('buttonLabels.create')" />
    </template>

    <div class="dropdown__wrapper">
      <b-dropdown-item href="#" class="item-submenu">
        <b-dropdown
          size="lg"
          variant="link"
          toggle-class="text-decoration-none"
          no-caret
          id="dropdown-2"
        >
          <template #button-content>
            <span>
              {{ $t('buttonLabels.material') }}
              <ArrowRightSimpleIcon />
            </span>
          </template>

          <div class="dropdown-submenu__wrapper">
            <b-dropdown-item href="#" v-b-modal.modal-material>
              <span class="drop-submenu-item"></span>{{ $t('label.document') }}
            </b-dropdown-item>
            <b-dropdown-item href="#" @click.prevent="openModalVideoCreation"
            >{{ $t('buttonLabels.video') }}
            </b-dropdown-item>
            <b-dropdown-item href="#" @click.prevent="createQuiz"
            >{{ $t('buttonLabels.quiz') }}
            </b-dropdown-item>
            <b-dropdown-item href="#" @click.prevent="openModalTaskCreation"
            >{{ $t('buttonLabels.task') }}
            </b-dropdown-item>
          </div>
        </b-dropdown>
      </b-dropdown-item>
      <b-dropdown-item v-if="contentLevel !== 2" href="#" @click.prevent="addGroup"
      >{{ $t('label.group') }}
      </b-dropdown-item>
    </div>
  </b-dropdown>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import AddButtonWithIcon from '@/components/Buttons/AddButtonWithIcon.vue';
import ArrowRightSimpleIcon from '@/components/Icons/ArrowRightSimpleIcon.vue';

export default {
  name: 'CourseContentDropdownButton',
  components: { AddButtonWithIcon, ArrowRightSimpleIcon },
  data: () => ({
    isDropdown2Visible: false,
  }),
  computed: {
    ...mapGetters('courseContentTeacher', ['contentLevel', 'groupIdOfLevelOne',
      'groupIdOfLevelTwo']),
  },
  mounted() {
    this.$root.$on('bv::dropdown::show', (bvEvent) => {
      if (bvEvent.componentId === 'dropdown-2') {
        this.isDropdown2Visible = true;
      }
    });
    this.$root.$on('bv::dropdown::hide', (bvEvent) => {
      if (bvEvent.componentId === 'dropdown-2') {
        this.isDropdown2Visible = false;
      }
      if (this.isDropdown2Visible) {
        bvEvent.preventDefault();
      }
    });
  },
  methods: {
    ...mapActions('modalType', ['setModalTypeIsCreated']),
    ...mapActions('courseContentTeacher', ['createActivity', 'createGroup', 'selectContentId']),
    async createQuiz() {
      // eslint-disable-next-line no-nested-ternary
      const parentId = this.contentLevel === 0
        ? this.$route.params.courseId
        : this.contentLevel === 1
          ? this.groupIdOfLevelOne
          : this.groupIdOfLevelTwo;

      await this.createActivity({
        activityType: 'quiz',
        parentId,
      })
        .then((id) => {
          if (id) {
            this.selectContentId(id);
            this.setModalTypeIsCreated(true);
            this.$bvModal.show('modal-quiz');
          }
        })
        .catch(console.log);
    },
    openModalVideoCreation() {
      this.setModalTypeIsCreated(true);
      this.$bvModal.show('modal-video');
    },
    openModalTaskCreation() {
      this.setModalTypeIsCreated(true);
      this.$bvModal.show('modal-task');
    },
    addGroup() {
      if (this.contentLevel === 0) {
        this.createGroup(this.$route.params.courseId);
      }
      if (this.contentLevel === 1) {
        this.createGroup(this.groupIdOfLevelOne);
      }
    },
  },
};
</script>
