<template>
  <div class="modal-material">
    <b-modal id="modal-edit-material" centered hide-footer @hidden="resetModal">
      <template #modal-title>
        <div class="modal-material__title">{{ $t('header.editMaterial') }}</div>
      </template>
      <template #modal-header-close>
        <SmallCloseButton />
      </template>
      <template #default>
        <div v-if="!!currentMaterial" class="selected-material">
          <div v-if="!selectedMaterial" class="empty-state">
            <div class="empty-state__icon">
              <UploadFileSmall />
            </div>
            <div class="empty-state__title">
              {{ $t('supportText.clickToSelect') }}
            </div>
            <div class="empty-state__subtitle">
              {{ $t('supportText.filesSupported') }}{{ `: .${pptx}, .${docx}, .${pdf}, `
              }}{{ $t('supportText.withMaximumSize') }}{{ formatBytes(fileSizeLimit) }}
            </div>
            <div class="empty-state__action">
              <AddButton :title="$t('buttonLabels.selectFile')" @click="loadMaterial" />
            </div>
          </div>
          <div v-else>
            <div class="selected-material__info info container">
              <div class="info__title">
                <span v-if="!selectedMaterial.fileSrc">{{ fileTypeTitle }}</span
                ><span v-else>{{ $t('header.fileAdded') }}</span>
              </div>
              <div class="info__subtitle">{{ $t('supportText.canReplaceOrEditMaterial') }}</div>
            </div>
            <div class="selected-material__tile tile">
              <div class="tile__left">
                <div>
                  {{ fileName }}
                </div>
              </div>
              <div class="tile__right">
                <div>({{ fileSize }})</div>
                <div class="tile__extension">.{{ fileExtension }}</div>
                <SmallEditButton @click="loadMaterial" />
                <SmallDeleteButton @remove="remove" />
              </div>
            </div>
          </div>
          <div class="selected-material__action meta">
            <div class="meta__title">
              <LmsInput
                id="material-title"
                :label="$t('placeholders.enterTitleForMaterial')"
                :value.sync="title"
                :class="{ invalid: $v.title.$dirty && !$v.title.required }"
              />
            </div>
            <div v-if="$v.title.$dirty && !$v.title.required" class="helper-text">
              {{ $t('errorMessages.pleaseFill') }}
            </div>
            <div class="meta__description">
              <LmsInput
                id="material-description"
                :label="$t('placeholders.enterDescriptionForMaterial')"
                :value.sync="description"
              />
            </div>
            <div class="meta__score">
              <div class="score-container">
                <span class="score-title">{{$t('label.scorePerView')}}</span>
                <LmsInput
                  id="material-score"
                  type="number"
                  placeholder="max 999"
                  :value.sync="max_score"
                  :class=" { invalid: $v.max_score.$dirty && !$v.max_score.maximumNotExceeded }"
                />
                <div
                  class="error-message"
                  :class="{
                    'error-message--activate':
                      $v.max_score.$dirty && !$v.max_score.maximumNotExceeded,
                  }"
                >
                  <p>{{ $t('label.maximum') }} 999</p>
                </div>
              </div>
            </div>
            <div v-if="isFileNotSelected" class="meta__action">
              <span>{{ $t('supportText.uploadToSaveOfCancel') }}</span>
            </div>
            <div v-else-if="isMaterialChanged" class="meta__action">
              <AddButton :title="$t('buttonLabels.saveChanges')" @click="saveMaterial" />
            </div>
          </div>
        </div>
      </template>
    </b-modal>
    <input
      type="file"
      id="imageLoader"
      class="d-none"
      @change="upload"
      ref="load"
      :accept="acceptedTypes"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import LmsInput from '@/components/LmsInput.vue';
import SmallCloseButton from '@/components/Buttons/SmallCloseButton.vue';
import SmallDeleteButton from '@/components/Buttons/SmallDeleteButton.vue';
import SmallEditButton from '@/components/Buttons/SmallEditButton.vue';
import AddButton from '@/components/Buttons/AddButton.vue';
import constantsMixin from '@/mixins/constants.mixin';
import { pdf, pptx, docx } from '@/utils/constants';
import MaterialService from '@/services/material.service';
import UploadFileSmall from '@/components/Icons/UploadFileSmall.vue';

export default {
  name: 'ModalEditMaterial',
  components: {
    UploadFileSmall,
    LmsInput,
    SmallCloseButton,
    SmallDeleteButton,
    SmallEditButton,
    AddButton,
  },
  data() {
    return {
      title: '',
      description: '',
      max_score: null,
      selectedMaterial: null,
      fileSizeLimit: 100 * 1024 * 1024, // Mbyte
      formData: null,
    };
  },
  validations: {
    title: {
      required,
    },
    max_score: {
      maximumNotExceeded(int) {
        return int < 1000;
      },
    },
  },
  mixins: [constantsMixin],
  computed: {
    ...mapGetters({ currentMaterial: 'courseContentTeacher/selectedContent' }),
    acceptedTypes() {
      return `.${this.pdf}, .${this.docx}, .${this.pptx}`;
    },
    whiteListExtensions() {
      return [this.pdf, this.pptx, this.docx];
    },
    fileTypeTitle() {
      switch (this.currentMaterial?.meta.fileExtension) {
        case pdf:
          return `PDF-${this.$t('label.document')}`;
        case docx:
          return this.$t('label.document');
        case pptx:
          return this.$t('label.presentation');
        default:
          return this.$t('label.file');
      }
    },

    fileName() {
      // eslint-disable-next-line no-nested-ternary
      return this.selectedMaterial?.fileName
        ? this.selectedMaterial.fileName
        : this.currentMaterial?.meta.fileName
          ? this.currentMaterial.meta.fileName
          : '';
    },
    fileSize() {
      // eslint-disable-next-line no-nested-ternary
      return this.selectedMaterial?.fileSize
        ? this.selectedMaterial.fileSize
        : this.currentMaterial?.meta.fileSize
          ? this.currentMaterial.meta.fileSize
          : '';
    },
    fileExtension() {
      // eslint-disable-next-line no-nested-ternary
      return this.selectedMaterial?.fileExtension
        ? this.selectedMaterial.fileExtension
        : this.currentMaterial?.meta.fileExtension
          ? this.currentMaterial.meta.fileExtension
          : '';
    },
    isMaterialChanged() {
      return (
        this.title !== this.currentMaterial?.name
        || this.description !== this.currentMaterial?.description
        || this.max_score !== this.currentMaterial?.max_score
        || (!this.selectedMaterial?.url && !this.selectedMaterial?.fileSrc)
        || !!this.selectedMaterial?.fileSrc
      );
    },
    isFileNotSelected() {
      return (
        !this.selectedMaterial?.url && !this.selectedMaterial?.fileSrc && !!this.currentMaterial
      );
    },
  },
  methods: {
    ...mapActions('courseContentTeacher', ['updateActivity', 'selectContentId']),
    ...mapActions('toaster', ['setToaster']),
    loadMaterial() {
      this.$refs.load.click();
    },
    upload(event) {
      const file = this.$refs.load.files[0];
      if (file) {
        if (!this.validateFileExtension(file)) {
          return;
        }
        if (!this.validateFileSize(file)) {
          return;
        }

        this.formData = new FormData();
        this.formData.append('file', file);

        let files = [];
        files = Array.from(event.target.files);

        files.forEach((f) => {
          const reader = new FileReader();
          const splitNam = f.name.split('.');
          reader.onload = (ev) => {
            const mime = ev.target.result.split(';')[0].split('data:')[1];
            this.formData.append('type', mime);
            this.selectedMaterial = {
              fileSrc: ev.target.result,
              fileName: f.name,
              fileExtension: splitNam[splitNam.length - 1],
              fileSize: this.formatBytes(f.size),
            };
          };

          reader.readAsDataURL(f);
        });
      }
    },
    validateFileExtension(file) {
      const spiltName = file.name.split('.');
      const flExt = spiltName[spiltName.length - 1];
      if (!this.whiteListExtensions.includes(flExt)) {
        const toast = {
          title: this.$t('errorMessages.fileNotSupported'),
          body: `${this.$t('supportText.onlyOfTheFormat')} .${this.pptx}, .${this.docx}, .${
            this.pdf
          }`,
        };
        this.setToaster({
          type: 'toast-danger',
          toast,
        });
        return false;
      }
      return true;
    },
    validateFileSize(file) {
      if (this.fileSizeLimit < file?.size) {
        const toast = {
          title: this.$t('errorMessages.sizeToLarge'),
          body: `${this.$t('supportText.maximumFileSize')} - ${this.formatBytes(
            this.fileSizeLimit,
          )}`,
        };
        this.setToaster({
          type: 'toast-danger',
          toast,
        });
        return false;
      }
      return true;
    },
    remove() {
      this.selectedMaterial = null;
      if (this.$refs.load?.value) {
        this.$refs.load.value = null;
      }
    },
    formatBytes(a, b = 2) {
      if (a === 0) return '0 Bytes';
      const c = b < 0 ? 0 : b;
      const d = Math.floor(Math.log(a) / Math.log(1024));
      return `${parseFloat((a / 1024 ** d).toFixed(c))} ${
        ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'][d]
      }`;
    },
    async saveMaterial() {
      this.$v.$touch();
      if (this.$v.$anyError) {
        return;
      }
      const currentMaterialId = this.currentMaterial._id;
      this.$bvModal.hide('modal-edit-material');

      if (this.selectedMaterial?.fileSrc) {
        const { selectedMaterial } = this;
        const { type } = this.currentMaterial;
        return MaterialService.uploadMaterial(this.formData)
          .then(async ({ data: { url } }) => {
            const meta = { ...selectedMaterial };
            delete meta.fileSrc;
            meta.url = url;
            this.updateActivity({
              id: currentMaterialId,
              activity: {
                name: this.title,
                description: this.description,
                max_score: this.max_score ? this.max_score : 0,
                meta,
                type,
              },
            });
          })
          .catch(console.log);
      }

      this.updateActivity({
        id: currentMaterialId,
        activity: {
          name: this.title,
          description: this.description,
          max_score: this.max_score ? this.max_score : 0,
        },
      });
    },
    resetState() {
      this.title = this.currentMaterial?.name;
      this.description = this.currentMaterial?.description;
      this.max_score = this.currentMaterial?.max_score;
      this.selectedMaterial = null;
      if (this.$refs.load?.value) {
        this.$refs.load.value = '';
      }
      this.formData = null;
    },
    resetModal() {
      this.selectContentId(null);
      this.resetState();
      if (this.$v) {
        this.$v.$reset();
      }
    },
  },
  watch: {
    currentMaterial: {
      handler(file) {
        if (file && file.type === 'material') {
          this.title = file.name;
          this.description = file.description;
          this.max_score = file.max_score;
          this.selectedMaterial = file.meta;
        }
      },
      deep: true,
    },
  },
};
</script>
