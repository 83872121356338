var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal-task"},[_c('b-modal',{attrs:{"id":"modal-task","centered":"","hide-footer":""},on:{"hidden":_vm.resetModal},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_c('div',{staticClass:"modal-task__title"},[_vm._v(" "+_vm._s(_vm.isModalTypeCreated ? _vm.$t('header.createTask') : _vm.$t('header.editTask'))+" ")])]},proxy:true},{key:"modal-header-close",fn:function(){return [_c('SmallCloseButton')]},proxy:true},{key:"default",fn:function(){return [_c('div',{staticClass:"selected-material",class:{ 'selected-material--creation': _vm.isModalTypeCreated }},[_c('div',{staticClass:"selected-material__info info"},[_c('div',{staticClass:"info__title"},[_vm._v(" "+_vm._s(_vm.modalTitle)+" ")]),_c('div',{staticClass:"info__subtitle"},[_vm._v(" "+_vm._s(_vm.isModalTypeCreated ? _vm.$t('supportText.canAttachAnyFormatFile') : _vm.$t('supportText.canReplaceFileTitleDescription'))+" ")])]),_c('div',{staticClass:"selected-material__action meta"},[_c('div',{staticClass:"meta__title"},[_c('LmsInput',{class:{
                invalid: _vm.$v.title.$dirty && !_vm.$v.title.required,
              },attrs:{"id":"material-title","label":_vm.$t('placeholders.enterTaskTitle'),"value":_vm.title},on:{"update:value":function($event){_vm.title=$event},"change":() => _vm.$v.title.$touch()}})],1),(_vm.$v.title.$dirty && !_vm.$v.title.required)?_c('div',{staticClass:"helper-text"},[_vm._v(" "+_vm._s(_vm.$t('errorMessages.pleaseFill'))+" ")]):_vm._e(),_c('div',{staticClass:"meta__description"},[_c('LmsInput',{attrs:{"id":"material-description","type":"textarea","label":_vm.$t('placeholders.enterTaskDescription'),"value":_vm.selectedMaterial.text},on:{"update:value":function($event){return _vm.$set(_vm.selectedMaterial, "text", $event)}}})],1),_c('div',{staticClass:"meta__file-section file-section"},[_c('div',{staticClass:"file-section__left"},[_c('AddButton',{attrs:{"title":_vm.buttonFileLabel,"size":"small","variant":[_vm.isFileSelected ? 'secondary-file' : 'secondary'][0]},on:{"click":_vm.openFile},scopedSlots:_vm._u([(_vm.isFileSelected)?{key:"prefixIcon",fn:function(){return [_c('FileIcon')]},proxy:true}:null,(_vm.isFileSelected)?{key:"suffixIcon",fn:function(){return [_c('CloseBoldSmallIcon',{on:{"click":_vm.removeFile}})]},proxy:true}:null],null,true)}),_c('p',{staticClass:"file-section__button-label"},[_vm._v(" "+_vm._s(_vm.$t('buttonLabels.fileWillBeAvailableForDownload'))+" ")])],1),_c('div',{staticClass:"file-section__right"})]),_c('div',{staticClass:"meta__attempt-block"},[_c('div',{staticClass:"attempt-block"},[_c('div',{staticClass:"attempt-block__item"},[_c('div',{staticClass:"attempt-block__left"},[_c('div',{staticClass:"attempt-block__title"},[_c('p',[_vm._v(_vm._s(_vm.$t('label.scorePerCorrectAnswerAmount')))])]),_c('div',{staticClass:"attempt-block__subtitle"},[_c('p',[_vm._v(_vm._s(_vm.$t('label.enterMaxPointsForAnswer')))])])]),_c('div',{staticClass:"attempt-block__right"},[_c('div',{staticClass:"score-container"},[_c('LmsInput',{class:{
                        invalid: _vm.$v.max_score.$dirty && !_vm.$v.max_score.maximumNotExceeded,
                      },attrs:{"id":"material-score","type":"number","value":_vm.max_score,"placeholder":"max 999"},on:{"update:value":function($event){_vm.max_score=$event}}}),_c('div',{staticClass:"error-message",class:{
                        'error-message--activate':
                          _vm.$v.max_score.$dirty && !_vm.$v.max_score.maximumNotExceeded,
                      }},[_c('p',[_vm._v(_vm._s(_vm.$t('label.maximum'))+" 999")])])],1)])]),_c('div',{staticClass:"attempt-block__item"},[_c('div',{staticClass:"attempt-block__left"},[_c('div',{staticClass:"attempt-block__title"},[_c('p',[_vm._v(_vm._s(_vm.$t('label.limitNumbersOfAttempts')))])]),_c('div',{staticClass:"attempt-block__subtitle"},[_c('p',[_vm._v(_vm._s(_vm.$t('label.byDefaultAttemptsAreUnlimited')))])])]),_c('div',{staticClass:"attempt-block__right"},[_c('div',{staticClass:"attempt-block__switcher"},[_c('LmsSwitch',{attrs:{"value":_vm.isEnabledAttempts,"size":"large","withIcon":""},on:{"update:value":function($event){_vm.isEnabledAttempts=$event}}})],1),_c('div',{staticClass:"attempt-block__input"},[_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(_vm.attempts),expression:"attempts",modifiers:{"number":true}}],ref:"input",staticClass:"simple-input",class:{
                        'simple-input--disabled': !_vm.isEnabledAttempts,
                        invalid: _vm.$v.attempts.$dirty && !_vm.$v.attempts.maximumNotExceeded,
                      },attrs:{"type":"number","disabled":!_vm.isEnabledAttempts},domProps:{"value":(_vm.attempts)},on:{"keypress":function($event){return _vm.isNumber($event)},"input":function($event){if($event.target.composing)return;_vm.attempts=_vm._n($event.target.value)},"blur":function($event){return _vm.$forceUpdate()}}})])]),_c('div',{staticClass:"error-message",class:{
                    'error-message--activate':
                      _vm.$v.attempts.$dirty && !_vm.$v.attempts.maximumNotExceeded,
                  }},[_c('p',[_vm._v(_vm._s(_vm.$t('label.maximum'))+" 999")])])])])]),_c('div',{staticClass:"meta__action"},[_c('AddButton',{attrs:{"isDisabled":_vm.loading,"title":_vm.isModalTypeCreated
                  ? _vm.$t('buttonLabels.createTask')
                  : _vm.$t('buttonLabels.saveChanges')},on:{"click":_vm.saveTask}})],1)])])]},proxy:true}])}),_c('input',{ref:"load",staticClass:"d-none",attrs:{"type":"file","id":"task-loader","accept":_vm.acceptedTypes},on:{"change":_vm.upload}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }